import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { nav, list } from '../components/menu/menu.module.css';

const Menu: React.FunctionComponent = () => (
    <nav className={nav}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>mathias sixten</title>
            <link rel="canonical" href="https://sixped.dk" />
        </Helmet>
        <ul className={list}>
            <li>
                <Link to="/" activeClassName="active">
                    about
                </Link>
            </li>
            <li>
                <Link to="/work" activeClassName="active">
                    work
                </Link>
            </li>
            <li>
                <Link to="/cv" activeClassName="active">
                    cv
                </Link>
            </li>
        </ul>
    </nav>
);

export default Menu;
